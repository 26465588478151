import { CalculatedPlayer, Colours, StatBlock } from '@alienheadwars/football-card-manager-model';
import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { localise } from '../../../../locale/locale';
import { Icon, IconId } from '../../../base/icon/Icon';
import { Avatar, AvatarComponentProps } from '../avatarComponents/Avatar';
import { CardDimensions, LargeCardDimensions, MediumCardDimensions, SmallCardDimensions } from './CardDimensions';
// import Avatar as MUIavatar from '@mui/material/Avatar';

//TODO move away from css files
import { getNationData, NationalLevel } from '@alienheadwars/football-card-manager-model/dist/players/v2/nationDetails';
import '@fontsource/norwester';
import IconisedString from '../../../base/icon/IconisedString';
import { Overlay } from '../../../base/overlay/Overlay';
import '../PlayerCard.css';

interface PlayerCardComponentProps {
    player: CalculatedPlayer;
    size?: 'small' | 'medium' | 'large'; // Optional size prop
    dimensions?: CardDimensions;
    onclick?(): void
}

export const ExpandableMiniPlayerCard = (props: PlayerCardComponentProps) => {

    const [viewPlayer, setViewPlayer] = React.useState(false)
    return <>{viewPlayer ? <Overlay onclick={() => setViewPlayer(false)}>
        <PlayerCardComponent {...props} />
    </Overlay> : ''}
        <PlayerCardComponent {...props} size='small' onclick={() => setViewPlayer(true)} />
    </>

}

export const PlayerCardComponent = (props: PlayerCardComponentProps) => {
    const { player, size, dimensions: customDimensions } = props;
    // Determine the dimensions to use
    const dimensions = customDimensions ? customDimensions // 1. Use custom dimensions if provided
        : size === 'small' // 2. Otherwise, use size prop
            ? SmallCardDimensions
            : size === 'medium'
                ? MediumCardDimensions
                : LargeCardDimensions; // 3. Default to Large if no size or custom dimensions are provided

    return (
        <CardFrame colours={player.colours} dimensions={dimensions} onclick={props.onclick}>
            <PlayerStats player={player} dimensions={dimensions} />
            <Artwork {...player} dimensions={dimensions} />

            {dimensions.showCharacterSheet ? (
                <>
                    <div style={{ opacity: 0, paddingBottom: `calc(3 * ${dimensions.borderWidth})` }}>
                        <PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                        {dimensions.showPlayerSummary && <PlayerSummary {...player} dimensions={dimensions} />}
                        <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={player.colours.primaryTextColour} secondaryColor={player.colours.primaryBackgroundColour} />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            left: '-5%',
                            top: `calc(2 * ${dimensions.borderWidth})`,
                            width: '110%',
                            overflowY: 'scroll',
                            overflowX: 'clip',
                            zIndex: 103,
                            scrollbarWidth: 'none',
                            /* Avatar placeholder */
                            // paddingTop: dimensions.artworkContainerHeight,
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{ 'height': `calc(${dimensions.artworkContainerHeight} - 2 * ${dimensions.borderWidth})` }} />
                        <PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                        <div style={{ 'height': 0, width: '90%', overflow: 'visible' }}>
                            <CharacterSheet {...player} dimensions={dimensions} />
                        </div>
                        <div style={{ opacity: 0, paddingBottom: '' }}>
                            <PlayerSummary {...player} dimensions={dimensions} />
                            <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={player.colours.primaryTextColour} secondaryColor={player.colours.primaryBackgroundColour} />
                        </div>
                    </div>
                </>
            )
                :
                <><PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                    {dimensions.showPlayerSummary && <PlayerSummary {...player} dimensions={dimensions} />}
                </>}
        </CardFrame>
    );
};

interface CharacterSheetProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const CharacterSheet = (props: CharacterSheetProps) => {
    const { dimensions } = props;
    const sectionBreak = <div style={{
        height: '1vmin',
        width: '100%',
        backgroundColor: props.colours.primaryColour,
        borderRadius: '1vmin',
        marginTop: dimensions.playerDescriptionHeaderFontSize,
        marginBottom: dimensions.playerDescriptionHeaderFontSize,
    }} />
    const nationData = getNationData(props.national.nationId)
    return (
        <Card sx={{
            marginLeft: dimensions.characterSheetPadding,
            marginRight: dimensions.characterSheetPadding,
            backgroundColor: props.colours.primaryBackgroundColour,
            borderRadius: '0',
            paddingBottom: dimensions.characterSheetPadding,
        }}>

            <Stack
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <PlayerSummary {...props} dimensions={dimensions} />
                {/* Swipe up icon */}
                <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={props.colours.primaryTextColour} secondaryColor={props.colours.primaryBackgroundColour} />

                <div style={{ width: '80%', textAlign: 'left' }}>


                    {/* Nationality Section */}
                    {/* header */}
                    {sectionBreak}
                    <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                        <img
                            src={`/images/flags/192x144/${props.national.nationId}.png`}
                            alt={`${props.national.nationId} flag`}
                            style={{ width: dimensions.playerDescriptionHeaderFontSize, marginRight: '1vmin' }}
                        />
                        {localise(`nationalities.${props.national.nationId}`)}
                        {props.national.nationalLevel && props.national.nationalLevel !== NationalLevel.BASIC && ` ${localise(`national.level.${props.national.nationalLevel}`)} `}
                    </Typography>
                    {/* Bonuses */}

                    {/* Stat bonuses */}
                    <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>{localise('national.bonus')}</Typography>
                    <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                        <StatBonuses statBlock={props.nationBonus.statBlock} colours={props.colours} />
                    </Typography>

                    {/* Favoured Enemy */}
                    <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>
                        {localise('national.favoured-enemy')}</Typography>
                    <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}> +4 vs                     <img
                        src={`/images/flags/192x144/${nationData.favoredOpponent}.png`}
                        alt={`${nationData.favoredOpponent} flag`}
                        style={{ height: dimensions.playerDescriptionHeaderFontSize }}
                    /> {localise(`nationalities.${nationData.favoredOpponent}`)}</Typography>
                    {/* Hero Trait */}
                    {props.national.nationalLevel !== NationalLevel.BASIC ? <>
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>
                            {localise('national.hero-trait')}</Typography>
                        <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            <b>{localise(`playertraits.${nationData.nationalTrait}`)}</b> - {localise(`playertraits.${nationData.nationalTrait}.bonuses`)}</Typography>
                    </> : ''
                    }
                    {/* Icon */}
                    {props.national.nationalLevel === NationalLevel.ICON ? <>
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>
                            {localise('national-icon')}</Typography>
                        <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            {localise(`playertraits.ICON.bonuses`)}</Typography>
                    </> : ''}



                    {sectionBreak}
                    {/* Role Section */}
                    <Typography variant="h6" component="span" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                        {localise(`playerroles.${props.roleId}`)}
                    </Typography>
                    <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>{localise('playerroles.bonuses')}</Typography>
                    <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                        {/* Display bonus stats */}
                        {props.roleBonus.statBlock &&
                            <StatBonuses statBlock={props.roleBonus.statBlock} colours={props.colours} />}
                    </Typography>
                    {/* Display traits descriptions */}
                    {props.roleBonus.playerTraits.length > 0 && <>
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>{localise('playerroles.traits')}</Typography>
                        {props.roleBonus.playerTraits.map((trait) => (
                            <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                                <span key={trait}>
                                    <b>{localise(`playertraits.${trait}`)}</b> - {localise(`playertraits.${trait}.bonuses`)}
                                </span>
                            </Typography>
                        ))}
                    </>
                    }
                    {sectionBreak}
                    {/* Race Section */}
                    <Typography variant="h6" component="span" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                        <img
                            src={`/images/races/${props.raceId.toLowerCase()}.png`}
                            alt={`${props.raceId}`}
                            style={{ width: dimensions.playerDescriptionHeaderFontSize, marginRight: '1vmin' }}
                        />
                        {localise(`races.${props.raceId}`)}
                    </Typography>
                    {/* Display traits descriptions */}
                    <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, color: ' rgba(128, 128, 128, 0.8)' }}>{localise('races.traits')}</Typography>
                    {props.racialBonus.playerTraits && props.racialBonus.playerTraits.map((trait) => (
                        <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            <span key={trait}>
                                <b>{localise(`playertraits.${trait}`)}</b> - {localise(`playertraits.${trait}.bonuses`)}
                            </span>
                        </Typography>
                    ))}
                </div>
            </Stack>
        </Card >
    );
};

const StatBonuses = (props: { statBlock: StatBlock, colours: Colours }) => {
    return <>{props.statBlock &&
        Object.entries(props.statBlock)
            .filter(([stat, value]) => value !== undefined)
            .map(([stat, value]) => (
                <IconisedString
                    key={stat}
                    string={` +${value}_${stat.toUpperCase()}_`}
                    iconProps={{
                        fontSize: 'inherit',
                        primaryColor: props.colours.primaryTextColour,
                        secondaryColor: props.colours.primaryBackgroundColour,
                    }}
                />
            ))}</>
}


interface PlayerStatsProps {
    player: CalculatedPlayer;
    dimensions: CardDimensions;
}

const PlayerStats = (props: PlayerStatsProps) => {
    const { combinedBonus } = props.player;
    const { dimensions } = props;

    return (
        <div
            style={{
                position: 'absolute',
                top: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                left: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                zIndex: 105,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <PlayerLevel level={props.player.level} colours={props.player.colours} dimensions={dimensions} />
            {combinedBonus.statBlock.shoot && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.shoot} iconId="SHOOT" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.pass && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.pass} iconId="PASS" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.run && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.run} iconId="RUN" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.intercept && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.intercept} iconId="INTERCEPT" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.tackle && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.tackle} iconId="TACKLE" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.block && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.block} iconId="BLOCK" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
        </div>
    );
};

interface PlayerStatProps {
    value: number;
    iconId: IconId;
    colours: Colours;
    dimensions: CardDimensions;
}

const PlayerStat = (props: PlayerStatProps) => {
    const { dimensions } = props;
    return (
        <Card
            style={{
                height: dimensions.playerStatSize,
                width: dimensions.playerStatSize,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: props.colours.primaryTextColour,
                fontWeight: '400',
                fontSize: dimensions.playerStatFontSize,
                lineHeight: dimensions.playerStatLineHeight,
                backgroundColor: props.colours.primaryBackgroundColour,
                borderRadius: '50%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                margin: dimensions.playerStatMargin,
            }}
        >
            <b>+{props.value}</b>{' '}
            <Icon
                iconId={props.iconId}
                fontSize="inherit"
                primaryColor={props.colours.primaryTextColour}
                secondaryColor={props.colours.primaryBackgroundColour}
            />
        </Card>
    );
};

interface PlayerLevelProps {
    level: number;
    colours: Colours;
    dimensions: CardDimensions;
}

const PlayerLevel = (props: PlayerLevelProps) => {
    const { dimensions } = props;
    return (
        <Card
            style={{
                height: dimensions.playerLevelSize,
                width: dimensions.playerLevelSize,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: props.colours.primaryTextColour,
                fontWeight: '400',
                fontSize: dimensions.playerLevelFontSize,
                lineHeight: dimensions.playerLevelLineHeight,
                backgroundColor: props.colours.primaryBackgroundColour,
                borderRadius: '50%',
            }}
        >
            <b>{props.level}</b>
            <Icon
                iconId="LEVEL"
                fontSize="inherit"
                primaryColor={props.colours.primaryTextColour}
                secondaryColor={props.colours.primaryBackgroundColour}
            />
        </Card>
    );
};

const impactFonts = ['Norwester', 'sans-serif'].join(',');

interface PlayerNameProps {
    name: string;
    colours: Colours;
    dimensions: CardDimensions;
}

const PlayerName = (props: PlayerNameProps) => {
    const { dimensions } = props;
    return (
        <Card
            sx={{
                backgroundColor: props.colours.primaryColour,
                width: '110%',
                justifyItems: 'center',
                zIndex: 104,
                top: 0,
                position: 'sticky',
                padding: dimensions.playerNamePadding,
            }}
        >
            <Typography
                gutterBottom
                variant="h3"
                component="div"
                sx={{
                    color: props.colours.primaryTextColour,
                    textAlign: 'center',
                    width: '100%',
                    marginBottom: 0,
                    fontSize: dimensions.playerNameFontSize,
                    lineHeight: dimensions.playerNameLineHeight,
                    fontFamily: impactFonts,
                }}
            >
                {localise(props.name)}
            </Typography>
        </Card>
    );
    // </div>
};

interface CardFrameProps {
    children: any;
    colours: Colours;
    dimensions: CardDimensions;
    onclick?(): void;
}

const CardFrame = (props: CardFrameProps) => {
    const { dimensions } = props;
    return (
        <Card
            onClick={props.onclick}
            sx={{
                width: dimensions.cardWidth,
                backgroundColor: props.colours.secondaryColour,
                borderRadius: dimensions.cardBorderRadius,
                overflow: 'visible', // This will allow the child components to overflow,
                position: 'relative',
                justifyItems: 'center',
                // border: dimensions.showPlayerSummary? 'none':`${dimensions.borderWidth} solid ${props.colours.primaryColour}`,
            }}
        >
            {dimensions.showPlayerSummary && <div
                style={{
                    position: 'absolute',
                    left: `calc(${dimensions.borderWidth} * 2)`,
                    top: `calc(${dimensions.borderWidth} * 2)`,
                    width: `calc(100% - (${dimensions.borderWidth} * 6))`,
                    height: `calc(100% - (${dimensions.borderWidth} * 6))`,
                    border: `${dimensions.borderWidth} solid ${props.colours.primaryColour}`,
                    borderRadius: dimensions.cardBorderRadius,
                    zIndex: 101,
                }}
            />}
            {props.children}
        </Card>
    );
};

interface ArtworkProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const Artwork = (props: ArtworkProps) => {
    const { dimensions } = props;
    const avatarComponentsProps: AvatarComponentProps[] = [...props.avatar ?? []];
    if (avatarComponentsProps.length === 0) {
        avatarComponentsProps.push({
            type: 'image',
            componentName: 'Player 2',
        });
    }
    const avatarComponents = avatarComponentsProps.map((props) => {
        return <Avatar {...props} style={{
            position: 'absolute',
            left: '0',
            top: '0',
            borderRadius: `${dimensions.cardBorderRadius} ${dimensions.cardBorderRadius} 0vh 0vh`,
            height: dimensions.artworkContainerHeight,
            width: dimensions.artworkContainerWidth,
        }} />;
    });
    return (
        <div
            style={{
                overflow: 'hidden',
                borderRadius: `${dimensions.cardBorderRadius} ${dimensions.cardBorderRadius} 0vh 0vh`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: dimensions.artworkContainerHeight,
                width: dimensions.artworkContainerWidth,
                pointerEvents: 'none',
            }}
        >
            {avatarComponents}
        </div>
    );
};

interface PlayerSummaryProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const PlayerSummary = (props: PlayerSummaryProps) => {
    const { dimensions } = props;
    return (
        <Stack
            direction="row"
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: dimensions.playerSummaryIconPadding,
                paddingRight: dimensions.playerSummaryIconPadding,
            }}
        >
            <img
                src={`/images/flags/192x144/${props.national.nationId}.png`}
                alt={`${props.national.nationId} flag`}
                style={{ width: dimensions.raceImageWidth }}
            />
            {/* Role */}
            <Typography variant="h2" component="span"
                style={{
                    minWidth: dimensions.playerSummaryIconSize,
                    maxWidth: dimensions.playerSummaryRoleMaxWidth,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: props.colours.primaryTextColour,
                    fontWeight: '400',
                    fontSize: dimensions.playerSummaryRoleFontSize,
                    lineHeight: dimensions.playerSummaryRoleLineHeight,
                    backgroundColor: props.colours.primaryBackgroundColour,
                    borderRadius: dimensions.playerSummaryIconSize,
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    margin: dimensions.playerSummaryIconPadding,
                    paddingTop: dimensions.playerSummaryRolePadding,
                    paddingBottom: dimensions.playerSummaryRolePadding,
                    paddingLeft: `calc(${dimensions.playerSummaryRolePadding} * 2)`,
                    paddingRight: `calc(${dimensions.playerSummaryRolePadding} * 2)`,
                    fontFamily: impactFonts,
                }}
            >
                {localise(`playerroles.${props.roleId}`)}
            </Typography>
            {/* Race */}
            <img
                src={`/images/races/${props.raceId.toLowerCase()}.png`}
                alt={`${props.raceId}`}
                style={{ width: dimensions.raceImageWidth }}
            />
        </Stack>
    );
};