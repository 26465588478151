import React from 'react'
import './Overlay.css'

export interface OverlayProps {
    children?: any,
    onclick?(): void
}

const doNothing = () => { }

export const Overlay = (props: OverlayProps) => {
    return (
        <div className='overlay' onClick={props.onclick ?? doNothing} style={
            { margin: 0 } /*required as mui sometimes adds margins to all children in containers with spacing*/ 
        }>
            {props.children}
        </div>
    )
}