import { calculatePlayer, Nations, PlayerInstance, } from '@alienheadwars/football-card-manager-model';
import { NationalLevel } from '@alienheadwars/football-card-manager-model/dist/players/v2/nationDetails';
import { Races } from '@alienheadwars/football-card-manager-model/dist/players/v2/race';
import PersonIcon from '@mui/icons-material/Person';
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { ExpandableMiniPlayerCard } from '../../cards/playerCards/v2/PlayerCard';
import { NewPackNewsCard } from "./newpack/NewPackNewsCard";
import { UnfinishedMatch } from './unfinishedMatch/UnfinishedMatchCard';

export interface NewsCardProps {
    parent(children: any): any
}

export const TeamNews = () => {

    const v2MatchEngine = <Grid item xs={12}><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div" >V2 Match Engine</Typography>
            <Typography variant="body2">
                Updated to support a more exciting, faster, simpler match engine.
            </Typography>
        </CardContent>
    </Card></Grid>

    const players: PlayerInstance[] = [
        //Goat
        {
            id: 'goats/goat',
            level: 7,
            raceId: Races.GOAT,
            roleId: 'FREE_ROLE',
            national: {
                nationId: Nations.ARGENTINA,
                nationalLevel: NationalLevel.ICON
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'image/png',
                    componentName: 'goats/argentinianGoat',
                },
            ],
             colours: {
                primaryColour: '#076fad',
                secondaryColour: '#d4d4cf',
                primaryTextColour: '#0e3f5e',
                primaryBackgroundColour: '#ffffff',
            },
        },
        //Goat
        {
            id: 'goats/portugese-devil',
            level: 7,
            raceId: Races.DEVIL,
            roleId: 'APEX_PREDATOR',
            national: {
                nationId: Nations.PORTUGAL,
                nationalLevel: NationalLevel.ICON
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'image/jpg',
                    componentName: 'goats/portugeseDevil',
                },
            ],
             colours: {
                primaryColour: '#d71f1d',
                secondaryColour: '#085c2a',
                primaryTextColour: '#210404',
                primaryBackgroundColour: '#b7e1c8',
            },
        },
        // Welsh Wizard
        {
            id: 'great-britain/english-league/treble-devils/welsh-wizard',
            level: 6,
            raceId: Races.WIZARD,
            roleId: 'CREATIVE_FORCE',
            national: {
                nationId: Nations.WALES,
                nationalLevel: NationalLevel.HERO
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'artwork',
                    componentName: 'great-britain/english-league/treble-devils/welsh-wizard',
                },
            ],
            colours: {
                primaryColour: '#e90c29',
                secondaryColour: 'black',
                primaryTextColour: 'black',
                primaryBackgroundColour: '#fef9ed',
            },
        },
        // Red Devil
        {
            id: 'great-britain/english-league/treble-devils/red-devil',
            level: 6,
            raceId: Races.DEVIL,
            roleId: 'COMPLETE_MIDFIELDER',
            national: {
                nationId: Nations.IRELAND,
                nationalLevel: NationalLevel.HERO
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'artwork',
                    componentName: 'great-britain/english-league/treble-devils/red-devil',
                },
            ],
            colours: {
                primaryColour: '#e90c29',
                secondaryColour: 'black',
                primaryTextColour: 'black',
                primaryBackgroundColour: '#fef9ed',
            },
        },

        // Adam
        {
            id: 'great-britain/sunday-league/team-chimera/skrog-skullcrusher',
            level: 2,
            raceId: Races.ORC,
            roleId: 'NO_NONSENSE_CENTRE_BACK',
            national: {
                nationId: Nations.WALES,
                nationalLevel: NationalLevel.BASIC
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'artwork',
                    componentName: 'great-britain/sunday-league/team-chimera/skrog-skullcrusher',
                },
            ],
            colours: {
                primaryColour: '#2c95cf',
                secondaryColour: '#bdd6e3',
                primaryTextColour: '#12374b',
                primaryBackgroundColour: '#e1e9ed',
            },
        },
        // Jack
        {
            id: 'great-britain/sunday-league/team-chimera/jack-russell',
            level: 2,
            raceId: Races.DOG,
            roleId: 'STRIKER',
            national: {
                nationId: Nations.GERMANY,
                nationalLevel: NationalLevel.BASIC
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'artwork',
                    componentName: 'great-britain/sunday-league/team-chimera/jack-russell',
                },
            ],
            colours: {
                primaryColour: '#2c95cf',
                secondaryColour: '#bdd6e3',
                primaryTextColour: '#12374b',
                primaryBackgroundColour: '#e1e9ed',
            },
        },
        // Mike
        {
            id: 'great-britain/sunday-league/team-chimera/mike-ruggers',
            level: 2,
            raceId: Races.HUMAN,
            roleId: "WING_BACK",
            national: {
                nationId: Nations.ENGLAND,
                nationalLevel: NationalLevel.BASIC
            },
            collectionId: 'previewedplayers',
            avatar: [
                {
                    type: 'artwork',
                    componentName: 'great-britain/sunday-league/team-chimera/mike-ruggers',
                },
            ],
            colours: {
                primaryColour: '#2c95cf',
                secondaryColour: '#bdd6e3',
                primaryTextColour: '#12374b',
                primaryBackgroundColour: '#e1e9ed',
            },
        }

    ]
    const playerComponents = players.map(player => calculatePlayer(player)).map(player => (
        <ExpandableMiniPlayerCard player={player}/> 
    ));



    const v2Cards = <Grid item xs={12}><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div" >V2 Cards</Typography>
            <Typography variant="body2">
                New card designs! To support new match engine, players are now constructed of a nation, a role and a fantasy race.
            </Typography>
            <p /><p /><p />
            <Stack direction="row" spacing={2} alignItems={'flex-start'} overflow={'scroll'} padding={2}>
                {playerComponents}
            </Stack>
        </CardContent>
    </Card></Grid>


    const teamColours = <Grid item xs={12}><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div">
                Team Colours <PersonIcon color='primary' />
            </Typography>
            <Typography variant="body2">
                Choose your colours! Unlock new kits through challenges. What colour will you be?
            </Typography>
        </CardContent>
    </Card></Grid>


    const parent = (children: any) => {
        return <Grid item xs>{children}</Grid>
    }
    return (
        <Grid container spacing={2}>
            <NewPackNewsCard parent={parent} />
            <UnfinishedMatch parent={parent} />
            {teamColours}
            {v2MatchEngine}
            {v2Cards}
        </Grid>
    )
}