"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttackingMidfielderRoleRarities = exports.AttackingMidfielderRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.AttackingMidfielderRoleBonuses = {
    ATTACKING_MIDFIELDER: {
        statBlock: {
            pass: 2,
            shoot: 2,
            run: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    FALSE_NINE: {
        statBlock: {
            pass: 2,
            shoot: 2,
            run: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.POCKETS],
    },
    FREE_ROLE: {
        statBlock: {
            run: 3,
            shoot: 3,
            pass: 2
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.POCKETS],
    },
    RAUMDEUTER: {
        statBlock: {
            pass: 3,
            run: 2,
            shoot: 3
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.POCKETS],
    },
    TREQUARTISTA: {
        statBlock: {
            pass: 3,
            run: 3,
            shoot: 2
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.POCKETS],
    },
    ADVANCED_PLAYMAKER: {
        statBlock: {
            pass: 4,
            run: 3,
            shoot: 2
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    CATALYST: {
        statBlock: {
            pass: 4,
            run: 3,
            shoot: 2
        },
        positionsEnabled: {
            defense: false,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.DIRECT],
    },
};
exports.AttackingMidfielderRoleRarities = {
    ATTACKING_MIDFIELDER: rarity_1.Rarity.COMMON,
    FALSE_NINE: rarity_1.Rarity.COMMON,
    FREE_ROLE: rarity_1.Rarity.RARE,
    RAUMDEUTER: rarity_1.Rarity.RARE,
    TREQUARTISTA: rarity_1.Rarity.RARE,
    ADVANCED_PLAYMAKER: rarity_1.Rarity.UNCOMMON,
    CATALYST: rarity_1.Rarity.RARE
};
