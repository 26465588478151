"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForwardRoleRarities = exports.ForwardRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.ForwardRoleBonuses = {
    FORWARD: {
        statBlock: {
            shoot: 3,
            run: 2,
            pass: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [],
    },
    STRIKER: {
        statBlock: {
            shoot: 4,
            run: 4,
            pass: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [],
    },
    TARGET_MAN: {
        statBlock: {
            shoot: 3,
            run: 2,
            pass: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.TARGET],
    },
    POACHER: {
        statBlock: {
            shoot: 3,
            run: 2,
            pass: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.CLINICAL],
    },
    COMPLETE_STRIKER: {
        statBlock: {
            shoot: 4,
            run: 4,
            pass: 4
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [],
    },
    APEX_PREDATOR: {
        statBlock: {
            shoot: 3,
            run: 2,
            pass: 1
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.CLINICAL, playerTraits_1.PlayerTraits.FOX_IN_THE_BOX],
    },
};
exports.ForwardRoleRarities = {
    FORWARD: rarity_1.Rarity.COMMON,
    STRIKER: rarity_1.Rarity.UNCOMMON,
    TARGET_MAN: rarity_1.Rarity.UNCOMMON,
    POACHER: rarity_1.Rarity.UNCOMMON,
    COMPLETE_STRIKER: rarity_1.Rarity.RARE,
    APEX_PREDATOR: rarity_1.Rarity.RARE
};
