"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerTraits = void 0;
var PlayerTraits;
(function (PlayerTraits) {
    PlayerTraits["PRESS_RESISTANT"] = "PRESS_RESISTANT";
    PlayerTraits["DIRECT"] = "DIRECT";
    PlayerTraits["GOAL_KEEPER"] = "GOAL_KEEPER";
    PlayerTraits["SWEEPER"] = "SWEEPER";
    PlayerTraits["COVERING_TACKLER"] = "COVERING_TACKLER";
    PlayerTraits["SOUL_SAP"] = "SOUL_SAP";
    PlayerTraits["LEGEND_OF_LEGENDS"] = "LEGEND_OF_LEGENDS";
    PlayerTraits["LONG_BALL"] = "LONG_BALL";
    PlayerTraits["GOAL_MACHINE"] = "GOAL_MACHINE";
    PlayerTraits["ROCKET_SHOT"] = "ROCKET_SHOT";
    PlayerTraits["CLINICAL"] = "CLINICAL";
    PlayerTraits["FOX_IN_THE_BOX"] = "FOX_IN_THE_BOX";
    PlayerTraits["POCKETS"] = "POCKETS";
    PlayerTraits["STRONG"] = "STRONG";
    PlayerTraits["PACEY"] = "PACEY";
    PlayerTraits["CHARGE"] = "CHARGE";
    PlayerTraits["STAMINA"] = "STAMINA";
    PlayerTraits["FINESSE"] = "FINESSE";
    PlayerTraits["TARGET"] = "TARGET";
    PlayerTraits["TIKI_TAKA"] = "TIKI_TAKA";
    PlayerTraits["LUCKY"] = "LUCKY";
    PlayerTraits["RELIABLE"] = "RELIABLE";
    PlayerTraits["FLAIR"] = "FLAIR";
    PlayerTraits["ADAPTABLE"] = "ADAPTABLE";
    PlayerTraits["PRODIGY"] = "PRODIGY";
    PlayerTraits["MANS_BEST_FRIEND"] = "MANS_BEST_FRIEND";
    PlayerTraits["INTELLIGENCE"] = "INTELLIGENCE";
    PlayerTraits["STAR"] = "STAR";
    PlayerTraits["UNDERDOG"] = "UNDERDOG";
    PlayerTraits["DOMINANT"] = "DOMINANT";
    PlayerTraits["HERO"] = "HERO";
    PlayerTraits["DETERMINED"] = "DETERMINED";
    PlayerTraits["ICON"] = "ICON";
})(PlayerTraits || (exports.PlayerTraits = PlayerTraits = {}));
