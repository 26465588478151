"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleRarities = exports.RoleBonuses = void 0;
exports.getRoleBonus = getRoleBonus;
exports.getRoleRarity = getRoleRarity;
const goalkeeperRoles_1 = require("./goalkeeperRoles");
const fullbackRoles_1 = require("./fullbackRoles");
const centrebackRoles_1 = require("./centrebackRoles");
const defensiveMidfielderRoles_1 = require("./defensiveMidfielderRoles");
const wideMidfielderRoles_1 = require("./wideMidfielderRoles");
const attackingMidfielderRoles_1 = require("./attackingMidfielderRoles");
const centralMidfielderRoles_1 = require("./centralMidfielderRoles");
const forwardRoles_1 = require("./forwardRoles");
exports.RoleBonuses = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, goalkeeperRoles_1.GoalkeeperRoleBonuses), fullbackRoles_1.FullBackRoleBonuses), centrebackRoles_1.CentreBackRoleBonuses), defensiveMidfielderRoles_1.DefensiveMidfielderRoleBonuses), wideMidfielderRoles_1.WideMidfielderRoleBonuses), attackingMidfielderRoles_1.AttackingMidfielderRoleBonuses), centralMidfielderRoles_1.CentralMidfielderRoleBonuses), forwardRoles_1.ForwardRoleBonuses);
exports.RoleRarities = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, goalkeeperRoles_1.GoalkeeperRoleRarities), fullbackRoles_1.FullBackRoleRarities), centrebackRoles_1.CentreBackRoleRarities), defensiveMidfielderRoles_1.DefensiveMidfielderRoleRarities), wideMidfielderRoles_1.WideMidfielderRoleRarities), attackingMidfielderRoles_1.AttackingMidfielderRoleRarities), centralMidfielderRoles_1.CentralMidfielderRoleRarities), forwardRoles_1.ForwardRoleRarities);
function getRoleBonus(role) {
    return exports.RoleBonuses[role];
}
function getRoleRarity(role) {
    return exports.RoleRarities[role];
}
