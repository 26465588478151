"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RaceDetails = exports.Races = void 0;
exports.getRaceBonus = getRaceBonus;
const playerTraits_1 = require("./playerTraits");
var Races;
(function (Races) {
    Races["HUMAN"] = "HUMAN";
    Races["WONDERKID"] = "WONDERKID";
    Races["ORC"] = "ORC";
    Races["DOG"] = "DOG";
    Races["WIZARD"] = "WIZARD";
    Races["DEVIL"] = "DEVIL";
    Races["BULL"] = "BULL";
    Races["CELESTIAL"] = "CELESTIAL";
    Races["GOAT"] = "GOAT";
    Races["HERO"] = "HERO";
    Races["SUPER_HERO"] = "SUPER_HERO";
    Races["HALFLING"] = "HALFLING";
    Races["MERMAID"] = "MERMAID";
    Races["MERMAN"] = "MERMAN";
    Races["DRAGON"] = "DRAGON";
})(Races || (exports.Races = Races = {}));
exports.RaceDetails = {
    [Races.HUMAN]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.ADAPTABLE] // Now has the EFFICIENT trait
    },
    [Races.WONDERKID]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.PRODIGY] // Now has the PRODIGY trait
    },
    [Races.ORC]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.STRONG]
    },
    [Races.DOG]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.MANS_BEST_FRIEND]
    },
    [Races.WIZARD]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.INTELLIGENCE]
    },
    [Races.DEVIL]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [
            playerTraits_1.PlayerTraits.SOUL_SAP,
            playerTraits_1.PlayerTraits.DOMINANT
        ]
    },
    [Races.BULL]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.CHARGE]
    },
    [Races.CELESTIAL]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.STAR]
    },
    [Races.GOAT]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [
            playerTraits_1.PlayerTraits.LEGEND_OF_LEGENDS,
            playerTraits_1.PlayerTraits.CHARGE
        ]
    },
    [Races.HERO]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.HERO]
    },
    [Races.HALFLING]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.LUCKY]
    },
    [Races.MERMAID]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR]
    },
    [Races.MERMAN]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR]
    },
    [Races.DRAGON]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [playerTraits_1.PlayerTraits.LEGEND_OF_LEGENDS]
    },
    [Races.SUPER_HERO]: {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [
            playerTraits_1.PlayerTraits.HERO,
            playerTraits_1.PlayerTraits.DETERMINED,
        ]
    }
};
function getRaceBonus(race) {
    return exports.RaceDetails[race];
}
