"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WideMidfielderRoleRarities = exports.WideMidfielderRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.WideMidfielderRoleBonuses = {
    WIDE_MIDFIELDER: {
        statBlock: {
            run: 2,
            pass: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    WINGER: {
        statBlock: {
            run: 3,
            pass: 3,
            shoot: 1,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    CREATIVE_FORCE: {
        statBlock: {
            run: 4,
            pass: 4,
            shoot: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    INVERTED_WINGER: {
        statBlock: {
            run: 4,
            pass: 3,
            shoot: 3,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [],
    },
    ENGINE: {
        statBlock: {
            run: 2,
            pass: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.STAMINA],
    },
    WORK_HORSE: {
        statBlock: {
            run: 2,
            pass: 2,
            tackle: 1,
            intercept: 1,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.STAMINA],
    },
};
exports.WideMidfielderRoleRarities = {
    WIDE_MIDFIELDER: rarity_1.Rarity.COMMON,
    WINGER: rarity_1.Rarity.UNCOMMON,
    CREATIVE_FORCE: rarity_1.Rarity.RARE,
    ENGINE: rarity_1.Rarity.UNCOMMON,
    WORK_HORSE: rarity_1.Rarity.RARE,
    INVERTED_WINGER: rarity_1.Rarity.RARE,
};
