"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePlayer = calculatePlayer;
const nationDetails_1 = require("./nationDetails");
const race_1 = require("./race");
const roles_1 = require("./roles/roles");
function calculatePlayer(player) {
    const roleBonus = (0, roles_1.getRoleBonus)(player.roleId);
    const racialBonus = (0, race_1.getRaceBonus)(player.raceId);
    // Calculate national bonus using nationalLevel from national metadata
    const nationBonus = (0, nationDetails_1.calculateNationalBonus)(player.national.nationId, player.national.nationalLevel);
    const combinedBonus = combineBonuses([roleBonus, racialBonus, nationBonus]);
    const calculatedPlayer = Object.assign(Object.assign({}, player), { combinedBonus: combinedBonus, nationBonus: nationBonus, roleBonus: roleBonus, racialBonus: racialBonus });
    return calculatedPlayer;
}
// Helper function to combine bonuses
function combineBonuses(bonuses) {
    var _a, _b, _c;
    const combinedBonus = {
        statBlock: {},
        positionsEnabled: {},
        playerTraits: [],
    };
    for (const bonus of bonuses) {
        // Combine StatBlock
        for (const stat in bonus.statBlock) {
            if (combinedBonus.statBlock[stat]) {
                combinedBonus.statBlock[stat] += bonus.statBlock[stat];
            }
            else {
                combinedBonus.statBlock[stat] = bonus.statBlock[stat];
            }
        }
        // Combine positionsEnabled - any true value should make the combined value true
        for (const position in bonus.positionsEnabled) {
            if (bonus.positionsEnabled[position]) {
                combinedBonus.positionsEnabled[position] = true;
            }
        }
        // Combine playerTraits - ensuring uniqueness
        for (const trait of bonus.playerTraits) {
            if (!combinedBonus.playerTraits.includes(trait)) {
                combinedBonus.playerTraits.push(trait);
            }
        }
        //Combine preferred opponents
        for (const opponent of (_a = bonus.preferredOpponent) !== null && _a !== void 0 ? _a : []) {
            if (!((_b = combinedBonus.preferredOpponent) === null || _b === void 0 ? void 0 : _b.includes(opponent))) {
                (_c = combinedBonus.preferredOpponent) === null || _c === void 0 ? void 0 : _c.push(opponent);
            }
        }
    }
    return combinedBonus;
}
