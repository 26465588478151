"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalkeeperRoleRarities = exports.GoalkeeperRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.GoalkeeperRoleBonuses = {
    GOAL_KEEPER: {
        statBlock: {
            block: 4,
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.GOAL_KEEPER],
    },
    SWEEPER_KEEPER: {
        statBlock: {
            block: 2,
            intercept: 2
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.GOAL_KEEPER, playerTraits_1.PlayerTraits.SWEEPER],
    },
    DISTRIBUTOR: {
        statBlock: {
            block: 4,
            pass: 3,
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.GOAL_KEEPER],
    },
    COMPLETE_GOALKEEPER: {
        statBlock: {
            block: 3,
            intercept: 2,
            pass: 2,
        },
        positionsEnabled: {
            defense: false,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.GOAL_KEEPER, playerTraits_1.PlayerTraits.SWEEPER],
    },
};
exports.GoalkeeperRoleRarities = {
    GOAL_KEEPER: rarity_1.Rarity.COMMON,
    SWEEPER_KEEPER: rarity_1.Rarity.UNCOMMON,
    DISTRIBUTOR: rarity_1.Rarity.UNCOMMON,
    COMPLETE_GOALKEEPER: rarity_1.Rarity.RARE,
};
