"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CentralMidfielderRoleRarities = exports.CentralMidfielderRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.CentralMidfielderRoleBonuses = {
    CENTRAL_MIDFIELDER: {
        statBlock: {
            pass: 2,
            intercept: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true
        },
        playerTraits: []
    },
    BOX_TO_BOX_MIDFIELDER: {
        statBlock: {
            run: 1,
            shoot: 1,
            pass: 2,
            tackle: 1,
            intercept: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true
        },
        playerTraits: []
    },
    PLAYMAKER: {
        statBlock: {
            pass: 4,
            run: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true
        },
        playerTraits: []
    },
    COMPLETE_MIDFIELDER: {
        statBlock: {
            run: 2,
            shoot: 2,
            pass: 2,
            tackle: 2,
            intercept: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true
        },
        playerTraits: []
    },
    MAESTRO: {
        statBlock: {
            pass: 4,
            shoot: 2,
            intercept: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true
        },
        playerTraits: [playerTraits_1.PlayerTraits.FINESSE]
    }
};
exports.CentralMidfielderRoleRarities = {
    CENTRAL_MIDFIELDER: rarity_1.Rarity.COMMON,
    BOX_TO_BOX_MIDFIELDER: rarity_1.Rarity.UNCOMMON,
    PLAYMAKER: rarity_1.Rarity.UNCOMMON,
    COMPLETE_MIDFIELDER: rarity_1.Rarity.RARE,
    MAESTRO: rarity_1.Rarity.RARE
};
