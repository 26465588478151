"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nations = void 0;
var Nations;
(function (Nations) {
    Nations["AFGHANISTAN"] = "af";
    Nations["ALBANIA"] = "al";
    Nations["ALGERIA"] = "dz";
    Nations["ANDORRA"] = "ad";
    Nations["ANGOLA"] = "ao";
    Nations["ANTIGUA_AND_BARBUDA"] = "ag";
    Nations["ARGENTINA"] = "ar";
    Nations["ARMENIA"] = "am";
    Nations["AUSTRALIA"] = "au";
    Nations["AUSTRIA"] = "at";
    Nations["AZERBAIJAN"] = "az";
    Nations["BAHAMAS"] = "bs";
    Nations["BAHRAIN"] = "bh";
    Nations["BANGLADESH"] = "bd";
    Nations["BARBADOS"] = "bb";
    Nations["BELARUS"] = "by";
    Nations["BELGIUM"] = "be";
    Nations["BELIZE"] = "bz";
    Nations["BENIN"] = "bj";
    Nations["BHUTAN"] = "bt";
    Nations["BOLIVIA"] = "bo";
    Nations["BOSNIA_AND_HERZEGOVINA"] = "ba";
    Nations["BOTSWANA"] = "bw";
    Nations["BRAZIL"] = "br";
    Nations["BRUNEI"] = "bn";
    Nations["BULGARIA"] = "bg";
    Nations["BURKINA_FASO"] = "bf";
    Nations["BURUNDI"] = "bi";
    Nations["CABO_VERDE"] = "cv";
    Nations["CAMBODIA"] = "kh";
    Nations["CAMEROON"] = "cm";
    Nations["CANADA"] = "ca";
    Nations["CENTRAL_AFRICAN_REPUBLIC"] = "cf";
    Nations["CHAD"] = "td";
    Nations["CHILE"] = "cl";
    Nations["CHINA"] = "cn";
    Nations["COLOMBIA"] = "co";
    Nations["COMOROS"] = "km";
    Nations["CONGO_DEMOCRATIC_REPUBLIC"] = "cd";
    Nations["CONGO_REPUBLIC"] = "cg";
    Nations["COSTA_RICA"] = "cr";
    Nations["COTE_D_IVOIRE"] = "ci";
    Nations["CROATIA"] = "hr";
    Nations["CUBA"] = "cu";
    Nations["CYPRUS"] = "cy";
    Nations["CZECH_REPUBLIC"] = "cz";
    Nations["DENMARK"] = "dk";
    Nations["DJIBOUTI"] = "dj";
    Nations["DOMINICA"] = "dm";
    Nations["DOMINICAN_REPUBLIC"] = "do";
    Nations["ECUADOR"] = "ec";
    Nations["EGYPT"] = "eg";
    Nations["EL_SALVADOR"] = "sv";
    Nations["EQUATORIAL_GUINEA"] = "gq";
    Nations["ERITREA"] = "er";
    Nations["ESTONIA"] = "ee";
    Nations["ESWATINI"] = "sz";
    Nations["ETHIOPIA"] = "et";
    Nations["FIJI"] = "fj";
    Nations["FINLAND"] = "fi";
    Nations["FRANCE"] = "fr";
    Nations["GABON"] = "ga";
    Nations["GAMBIA"] = "gm";
    Nations["GEORGIA"] = "ge";
    Nations["GERMANY"] = "de";
    Nations["GHANA"] = "gh";
    Nations["GREECE"] = "gr";
    Nations["GRENADA"] = "gd";
    Nations["GUATEMALA"] = "gt";
    Nations["GUINEA"] = "gn";
    Nations["GUINEA_BISSAU"] = "gw";
    Nations["GUYANA"] = "gy";
    Nations["HAITI"] = "ht";
    Nations["HONDURAS"] = "hn";
    Nations["HUNGARY"] = "hu";
    Nations["ICELAND"] = "is";
    Nations["INDIA"] = "in";
    Nations["INDONESIA"] = "id";
    Nations["IRAN"] = "ir";
    Nations["IRAQ"] = "iq";
    Nations["IRELAND"] = "ie";
    Nations["ISRAEL"] = "il";
    Nations["ITALY"] = "it";
    Nations["IVORY_COAST"] = "ci";
    Nations["JAMAICA"] = "jm";
    Nations["JAPAN"] = "jp";
    Nations["JORDAN"] = "jo";
    Nations["KAZAKHSTAN"] = "kz";
    Nations["KENYA"] = "ke";
    Nations["KIRIBATI"] = "ki";
    Nations["KOREA_NORTH"] = "kp";
    Nations["KOREA_SOUTH"] = "kr";
    Nations["KOSOVO"] = "xk";
    Nations["KUWAIT"] = "kw";
    Nations["KYRGYZSTAN"] = "kg";
    Nations["LAOS"] = "la";
    Nations["LATVIA"] = "lv";
    Nations["LEBANON"] = "lb";
    Nations["LESOTHO"] = "ls";
    Nations["LIBERIA"] = "lr";
    Nations["LIBYA"] = "ly";
    Nations["LIECHTENSTEIN"] = "li";
    Nations["LITHUANIA"] = "lt";
    Nations["LUXEMBOURG"] = "lu";
    Nations["MADAGASCAR"] = "mg";
    Nations["MALAWI"] = "mw";
    Nations["MALAYSIA"] = "my";
    Nations["MALDIVES"] = "mv";
    Nations["MALI"] = "ml";
    Nations["MALTA"] = "mt";
    Nations["MARSHALL_ISLANDS"] = "mh";
    Nations["MAURITANIA"] = "mr";
    Nations["MAURITIUS"] = "mu";
    Nations["MEXICO"] = "mx";
    Nations["MICRONESIA"] = "fm";
    Nations["MOLDOVA"] = "md";
    Nations["MONACO"] = "mc";
    Nations["MONGOLIA"] = "mn";
    Nations["MONTENEGRO"] = "me";
    Nations["MOROCCO"] = "ma";
    Nations["MOZAMBIQUE"] = "mz";
    Nations["MYANMAR"] = "mm";
    Nations["NAMIBIA"] = "na";
    Nations["NAURU"] = "nr";
    Nations["NEPAL"] = "np";
    Nations["NETHERLANDS"] = "nl";
    Nations["NEW_ZEALAND"] = "nz";
    Nations["NICARAGUA"] = "ni";
    Nations["NIGER"] = "ne";
    Nations["NIGERIA"] = "ng";
    Nations["NORTH_MACEDONIA"] = "mk";
    Nations["NORWAY"] = "no";
    Nations["OMAN"] = "om";
    Nations["PAKISTAN"] = "pk";
    Nations["PALAU"] = "pw";
    Nations["PALESTINE"] = "ps";
    Nations["PANAMA"] = "pa";
    Nations["PAPUA_NEW_GUINEA"] = "pg";
    Nations["PARAGUAY"] = "py";
    Nations["PERU"] = "pe";
    Nations["PHILIPPINES"] = "ph";
    Nations["POLAND"] = "pl";
    Nations["PORTUGAL"] = "pt";
    Nations["QATAR"] = "qa";
    Nations["ROMANIA"] = "ro";
    Nations["RUSSIA"] = "ru";
    Nations["RWANDA"] = "rw";
    Nations["SAINT_KITTS_AND_NEVIS"] = "kn";
    Nations["SAINT_LUCIA"] = "lc";
    Nations["SAINT_VINCENT_AND_THE_GRENADINES"] = "vc";
    Nations["SAMOA"] = "ws";
    Nations["SAN_MARINO"] = "sm";
    Nations["SAO_TOME_AND_PRINCIPE"] = "st";
    Nations["SAUDI_ARABIA"] = "sa";
    Nations["SENEGAL"] = "sn";
    Nations["SERBIA"] = "rs";
    Nations["SEYCHELLES"] = "sc";
    Nations["SIERRA_LEONE"] = "sl";
    Nations["SINGAPORE"] = "sg";
    Nations["SLOVAKIA"] = "sk";
    Nations["SLOVENIA"] = "si";
    Nations["SOLOMON_ISLANDS"] = "sb";
    Nations["SOMALIA"] = "so";
    Nations["SOUTH_AFRICA"] = "za";
    Nations["SOUTH_SUDAN"] = "ss";
    Nations["SPAIN"] = "es";
    Nations["SRI_LANKA"] = "lk";
    Nations["SUDAN"] = "sd";
    Nations["SURINAME"] = "sr";
    Nations["SWEDEN"] = "se";
    Nations["SWITZERLAND"] = "ch";
    Nations["SYRIA"] = "sy";
    Nations["TAIWAN"] = "tw";
    Nations["TAJIKISTAN"] = "tj";
    Nations["TANZANIA"] = "tz";
    Nations["THAILAND"] = "th";
    Nations["TIMOR_LESTE"] = "tl";
    Nations["TOGO"] = "tg";
    Nations["TONGA"] = "to";
    Nations["TRINIDAD_AND_TOBAGO"] = "tt";
    Nations["TUNISIA"] = "tn";
    Nations["TURKEY"] = "tr";
    Nations["TURKMENISTAN"] = "tm";
    Nations["TUVALU"] = "tv";
    Nations["UGANDA"] = "ug";
    Nations["UKRAINE"] = "ua";
    Nations["UNITED_ARAB_EMIRATES"] = "ae";
    Nations["UNITED_KINGDOM"] = "gb";
    Nations["ENGLAND"] = "gb-eng";
    Nations["SCOTLAND"] = "gb-sct";
    Nations["WALES"] = "gb-wls";
    Nations["NORTHERN_IRELAND"] = "gb-nir";
    Nations["USA"] = "us";
    Nations["URUGUAY"] = "uy";
    Nations["UZBEKISTAN"] = "uz";
    Nations["VANUATU"] = "vu";
    Nations["VATICAN_CITY"] = "va";
    Nations["VENEZUELA"] = "ve";
    Nations["VIETNAM"] = "vn";
    Nations["YEMEN"] = "ye";
    Nations["ZAMBIA"] = "zm";
    Nations["ZIMBABWE"] = "zw";
})(Nations || (exports.Nations = Nations = {}));
