"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefensiveMidfielderRoleRarities = exports.DefensiveMidfielderRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.DefensiveMidfielderRoleBonuses = {
    DEFENSIVE_MIDFIELDER: {
        statBlock: {
            pass: 2,
            intercept: 2,
            tackle: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [],
    },
    PLAYMAKER: {
        statBlock: {
            pass: 4,
            intercept: 3,
            tackle: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [],
    },
    DESTROYER: {
        statBlock: {
            tackle: 4,
            pass: 2,
            intercept: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [],
    },
    PRESS_RESISTANT_DMF: {
        statBlock: {
            pass: 2,
            intercept: 2,
            tackle: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.PRESS_RESISTANT],
    },
    REGISTA: {
        statBlock: {
            pass: 5,
            intercept: 2,
            tackle: 1,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.LONG_BALL],
    },
    SHIELD: {
        statBlock: {
            pass: 2,
            intercept: 2,
            tackle: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.PRESS_RESISTANT], //Assuming "shield trait" is the same as PRESS_RESISTANT, add SHIELD trait if not.
    },
};
exports.DefensiveMidfielderRoleRarities = {
    DEFENSIVE_MIDFIELDER: rarity_1.Rarity.COMMON,
    PLAYMAKER: rarity_1.Rarity.UNCOMMON,
    DESTROYER: rarity_1.Rarity.COMMON,
    PRESS_RESISTANT_DMF: rarity_1.Rarity.UNCOMMON,
    REGISTA: rarity_1.Rarity.RARE,
    SHIELD: rarity_1.Rarity.RARE,
};
