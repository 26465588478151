"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CentreBackRoleRarities = exports.CentreBackRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.CentreBackRoleBonuses = {
    CENTRE_BACK: {
        statBlock: {
            tackle: 2,
            block: 2,
            intercept: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
    NO_NONSENSE_CENTRE_BACK: {
        statBlock: {
            tackle: 3,
            block: 3,
            intercept: 3,
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
    BALL_PLAYING_CENTRE_BACK: {
        statBlock: {
            tackle: 2,
            block: 2,
            intercept: 2,
            pass: 3
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
    SWEEPER: {
        statBlock: {
            tackle: 2,
            block: 2,
            intercept: 2,
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.SWEEPER],
    },
    COMPLETE_CENTRE_BACK: {
        statBlock: {
            tackle: 3,
            block: 3,
            intercept: 3,
            pass: 3
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
    LIBERO: {
        statBlock: {
            tackle: 2,
            block: 2,
            intercept: 2,
            pass: 3
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.SWEEPER],
    },
    THE_WALL: {
        statBlock: {
            tackle: 4,
            intercept: 4,
            block: 4
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
};
exports.CentreBackRoleRarities = {
    CENTRE_BACK: rarity_1.Rarity.COMMON,
    NO_NONSENSE_CENTRE_BACK: rarity_1.Rarity.UNCOMMON,
    BALL_PLAYING_CENTRE_BACK: rarity_1.Rarity.UNCOMMON,
    SWEEPER: rarity_1.Rarity.UNCOMMON,
    COMPLETE_CENTRE_BACK: rarity_1.Rarity.RARE,
    LIBERO: rarity_1.Rarity.RARE,
    THE_WALL: rarity_1.Rarity.RARE,
};
