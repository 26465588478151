export interface CardDimensions {
    cardWidth: string;
    cardBorderRadius: string;
    cardPadding: string;

    borderWidth: string;

    playerNameFontSize: string;
    playerNameLineHeight: string;
    playerNamePadding: string;

    playerLevelSize: string;
    playerLevelFontSize: string;
    playerLevelLineHeight: string;

    playerStatSize: string;
    playerStatFontSize: string;
    playerStatLineHeight: string;
    playerStatMargin: string;

    playerDescriptionWidth: string;
    playerDescriptionPadding: string;
    playerDescriptionHeaderFontSize: string;
    playerDescriptionBodyFontSize: string;

    artworkContainerHeight: string;
    artworkContainerWidth: string;

    playerSummaryIconSize: string;
    playerSummaryIconPadding: string;
    playerSummaryRoleFontSize: string;
    playerSummaryRoleLineHeight: string;
    playerSummaryRolePadding: string;
    playerSummaryRoleMaxWidth: string;

    characterSheetAvatarPlaceholderHeight: string;
    characterSheetPadding: string;

    flagWidth: string;
    raceImageWidth: string;

    //show/hide flags
    showCharacterSheet: boolean;
    showPlayerStats: boolean;
    showPlayerSummary: boolean;
}

export const createCardDimensions = (
    baseWidthVh: number,
    baseFontSizeVh: number,
    showCharacterSheet: boolean = true,
    showPlayerStats: boolean = true,
    showPlayerSummary: boolean = true
): CardDimensions => {
    const aspectRatio = 3 / 4; // Target aspect ratio (width/height)

    // Calculate card width and height
    const cardWidth = `min(${(baseWidthVh)}vh, ${(baseWidthVh / aspectRatio)}vw)`;

    const borderRadius = `calc(${cardWidth} * 0.12)`;
    const borderWidth = `calc(${cardWidth} * 0.02)`;
    const padding = `calc(${cardWidth} * 0.02)`;

    // Responsive font size calculation
    const responsiveFontSize = `min(${baseFontSizeVh}vh, ${(baseFontSizeVh / aspectRatio)}vw)`;

    const playerNameFontSize = `calc(${responsiveFontSize} * 1.05)`;
    const playerNameLineHeight = `calc(${responsiveFontSize} * 1.1)`;
    const playerNamePadding = `calc(${responsiveFontSize} * 0.1)`;

    const playerLevelSize = `calc(${responsiveFontSize} * 2)`;
    const playerLevelFontSize = `calc(${responsiveFontSize} * 1)`;
    const playerLevelLineHeight =playerLevelFontSize;

    const playerStatSize = `calc(${playerLevelSize} * 0.8)`;
    const playerStatFontSize = `calc(${playerLevelFontSize} * 0.6)`;
    const playerStatLineHeight = playerStatFontSize;
    const playerStatMargin = `calc(${responsiveFontSize} * 0.1)`;

    const playerDescriptionWidth = "90%";
    const playerDescriptionPadding = padding;
    const playerDescriptionHeaderFontSize = `calc(${responsiveFontSize} * 0.5)`;;
    const playerDescriptionBodyFontSize = `calc(${responsiveFontSize} * 0.35)`;;

    const artworkContainerHeight = cardWidth;
    const artworkContainerWidth = cardWidth;

    const playerSummaryIconSize = `calc(${cardWidth} * 0.1625)`;
    const playerSummaryIconPadding = `calc(${responsiveFontSize} * 0.1)`;
    const playerSummaryRoleFontSize = `calc(${responsiveFontSize} * 0.6)`;
    const playerSummaryRoleLineHeight = `calc(${responsiveFontSize} * 0.5)`;
    const playerSummaryRolePadding = `calc(${responsiveFontSize} * 0.15)`;
    const playerSummaryRoleMaxWidth = `calc(${cardWidth} * 0.325)`;

    const characterSheetAvatarPlaceholderHeight = `calc(${cardWidth} - 1vh)`;
    const characterSheetPadding = `calc(${borderWidth} * 3 + (${playerLevelSize}*0.5))`;;

    const flagWidth = `calc(${cardWidth} * 0.1125)`;
    const raceImageWidth = `calc(${cardWidth} * 0.1125)`;

    return {
        // ... (other properties remain the same)
        cardWidth,
        cardBorderRadius: borderRadius,
        cardPadding: padding,
        borderWidth,

        playerNameFontSize,
        playerNameLineHeight,
        playerNamePadding,

        playerLevelSize,
        playerLevelFontSize,
        playerLevelLineHeight,

        playerStatSize,
        playerStatFontSize,
        playerStatLineHeight,
        playerStatMargin,

        playerDescriptionWidth,
        playerDescriptionPadding,
        playerDescriptionHeaderFontSize,
        playerDescriptionBodyFontSize,

        artworkContainerHeight,
        artworkContainerWidth,

        playerSummaryIconSize,
        playerSummaryIconPadding,
        playerSummaryRoleFontSize,
        playerSummaryRoleLineHeight,
        playerSummaryRolePadding,
        playerSummaryRoleMaxWidth,

        characterSheetAvatarPlaceholderHeight,
        characterSheetPadding,

        flagWidth,
        raceImageWidth,

        showCharacterSheet,
        showPlayerStats,
        showPlayerSummary,
    };
};

// Predefined sizes using the helper function
export const SmallCardDimensions: CardDimensions = createCardDimensions(
    10, // cardWidthvmin
    1.8, // baseFontSizevmin
    false,
    false,
    false
);

export const MediumCardDimensions: CardDimensions = createCardDimensions(
    25, // cardWidthvmin
    5, // baseFontSizevmin
    false,
    true,
    true
);

export const LargeCardDimensions: CardDimensions = createCardDimensions(
    61, // cardWidthvmin
    7, // baseFontSizevmin
    true,
    true,
    true
);