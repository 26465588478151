import React from "react"
import { ShirtSleeves } from "./shirts/ShirtSleeves"
import { ShirtSolid } from "./shirts/ShirtSolid"
import { ShirtTwoTone } from "./shirts/ShirtTwoTone"
import { ShirtStripes } from "./shirts/ShirtStripes"
import { AvatarComponent } from "@alienheadwars/football-card-manager-model"

export interface AvatarComponentProps extends AvatarComponent {
    className?: string,
    style?: any,
}

interface AvatarComponentTranslator {
    (props: AvatarComponentProps): any
}

const avatarComponents: { [key: string]: AvatarComponentTranslator } = {
    "shirt-sleeves": (props: AvatarComponentProps) => { return <ShirtSleeves {...props} /> },
    "shirt-solid": (props: AvatarComponentProps) => { return <ShirtSolid {...props} /> },
    "shirt-stripes": (props: AvatarComponentProps) => { return <ShirtStripes {...props} /> },
    "shirt-two-tone": (props: AvatarComponentProps) => { return <ShirtTwoTone {...props} /> }
}



export const Avatar = (props: AvatarComponentProps) => {
    if (props.type === 'image') {
        return <img
            src={`/images/players/${props.componentName}.svg`}
            alt="playerpic"
            className={props.className ?? 'mini-card-avatar'} style={{
                zIndex: props.zIndex,
                ...props.style,
            }}
        />
    }
    else if (props.type === 'image/png') {
        return <img
            src={`/images/players/${props.componentName}.png`}
            alt="playerpic"
            className={props.className ?? 'mini-card-avatar'} style={{
                zIndex: props.zIndex,
                ...props.style,
            }}
        />
    }
    else if (props.type === 'image/jpg') {
        return <img
            src={`/images/players/${props.componentName}.jpg`}
            alt="playerpic"
            className={props.className ?? 'mini-card-avatar'} style={{
                zIndex: props.zIndex,
                ...props.style,
            }}
        />
    }
    else if (props.type === 'artwork') {
        return <img
            src={`/images/players/artwork/${props.componentName}.png`}
            alt="playerpic"
            className={props.className ?? 'mini-card-avatar'} style={{
                zIndex: props.zIndex,
                ...props.style,
            }}
        />
    }
    const svg = avatarComponents[props.componentName]
    if (svg) {
        return (svg(props))

    }
    console.log("Unknown avatar type", props)
    return <></>
}
