"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullBackRoleRarities = exports.FullBackRoleBonuses = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
exports.FullBackRoleBonuses = {
    FULL_BACK: {
        statBlock: {
            tackle: 3,
            intercept: 2,
            block: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [],
    },
    COVERING_FULL_BACK: {
        statBlock: {
            tackle: 3,
            intercept: 2,
            block: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.COVERING_TACKLER],
    },
    WING_BACK: {
        statBlock: {
            tackle: 3,
            intercept: 2,
            pass: 2,
            block: 1
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [],
    },
    LOCK_DOWN_DEFENDER: {
        statBlock: {
            tackle: 4,
            intercept: 3,
            block: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.COVERING_TACKLER],
    },
    INVERTED_WING_BACK: {
        statBlock: {
            tackle: 3,
            intercept: 3,
            pass: 3,
            block: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: false,
        },
        playerTraits: [],
    },
    COMPLETE_FULL_BACK: {
        statBlock: {
            tackle: 3,
            intercept: 2,
            pass: 2,
            block: 2
        },
        positionsEnabled: {
            defense: true,
            midfield: false,
            attack: false,
        },
        playerTraits: [playerTraits_1.PlayerTraits.COVERING_TACKLER],
    },
    MARAUDING_WING_BACK: {
        statBlock: {
            tackle: 2,
            intercept: 2,
            pass: 2,
            run: 1,
        },
        positionsEnabled: {
            defense: true,
            midfield: true,
            attack: true,
        },
        playerTraits: [playerTraits_1.PlayerTraits.STAMINA],
    },
};
exports.FullBackRoleRarities = {
    FULL_BACK: rarity_1.Rarity.COMMON,
    COVERING_FULL_BACK: rarity_1.Rarity.UNCOMMON,
    WING_BACK: rarity_1.Rarity.UNCOMMON,
    LOCK_DOWN_DEFENDER: rarity_1.Rarity.RARE,
    INVERTED_WING_BACK: rarity_1.Rarity.RARE,
    COMPLETE_FULL_BACK: rarity_1.Rarity.RARE,
    MARAUDING_WING_BACK: rarity_1.Rarity.RARE
};
