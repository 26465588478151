import React from 'react';
import { Icon, IconId, IconProps } from './Icon'; // Import Icon component and its props

interface StringWithIconsProps {
    string: string;
    iconProps?: IconProps;
}

const IconisedString: React.FC<StringWithIconsProps> = ({ string, iconProps = {} }) => {
    const regex = /_([^_]+)_/g;

    return (
        <>
            {string.split(regex).map((part, index) => {
                const icon = Icon({iconId:part as IconId, ...iconProps})
                if (icon) {
                    return (
                        icon
                    );
                }
                else {
                    return <span>{part}</span>
                }
            })}
        </>
    );
};

export default IconisedString;