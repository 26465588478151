import GroupsIcon from '@mui/icons-material/Groups';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TeamContext } from "./TeamContext";

export const TeamNav = () => {
    const history = useHistory()
    const baseUrl = useContext(TeamContext).getBaseUrl()
    const location = useLocation();

    const [selected, setSelected] = useState<string | undefined>(undefined);

    useEffect(() => {
        // ['myteam','play','options'].forEach( item=>{
        //     if(location.pathname.startsWith(`${baseUrl}/${item}`)){
        //         setSelected(item)
        //     }
        // })
        setSelected(['myteam', 'play', 'options'].find(item => location.pathname.startsWith(`${baseUrl}/${item}`)))
    }, [location, baseUrl, setSelected])

    if (!selected) {
        return <></>
    }

    return (<>
        {/* Dirty overflow fix, without this the bottom navigation blocks the end of a scrollable part*/}
        <Paper sx={{ opacity: 0 }}><BottomNavigation
        >
            <BottomNavigationAction label="My Team" value="myteam" icon={<GroupsIcon />} />
        </BottomNavigation>
        </Paper>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 , zIndex: 1000}} elevation={3}>
            <BottomNavigation
                showLabels
                value={selected}
                onChange={(event, newValue) => {
                    history.push(`${baseUrl}/${newValue}`)
                }}
            >
                <BottomNavigationAction label="My Team" value="myteam" icon={<GroupsIcon />} />
                <BottomNavigationAction label="Play" value="play" icon={<SportsSoccerIcon />} />
                <BottomNavigationAction label="Options" value="options" icon={<SettingsApplicationsIcon />} />
            </BottomNavigation>
        </Paper></>
    )
}